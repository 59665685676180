const SiteMaintenance = () => {
  document.title = 'Unavailable service';

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <h1 className="govuk-heading-l">
          Tell Border Force and HMRC you are sailing to or from the UK in a pleasure craft is
          currently unavailable.
        </h1>
        <p className="govuk-body">
          You can submit Advance Passenger Information (API) for an imminent planned voyage, by
          downloading and completing the e-C1331 from{' '}
          <a
            className="govuk-link"
            href="https://www.gov.uk/government/publications/sailing-a-leisure-craft-to-and-from-the-uk"
          >
            Sailing pleasure craft to and from the UK.
          </a>
        </p>
        <p className="govuk-body">
          The e-C1331 is an excel template that will allow data to be submitted via email to the
          relevant regional Border Force command. Full details and guidance can be found on the
          template.
        </p>
        <p className="govuk-body">
          Alternatively the PDF version of the C1331 form can be downloaded from the same location,
          completed offline and sent via post. Instructions for doing so can be found on the form.
        </p>
        <p className="govuk-body">We apologise for any inconvenience.</p>
      </div>
    </div>
  );
};

export default SiteMaintenance;
