import PropTypes from 'prop-types';
import { DUPLICATE_PEOPLE_ERROR, DUPLICATE_VESSEL_ERROR } from '../../constants/AppApiConstants';
import { ERROR_MESSAGE_URL, MESSAGE_URL, SIGN_IN_URL } from '../../constants/AppUrlConstants';
import Auth from '../Auth';

const handleAuthErrors = ({
  error,
  errorMessage,
  errorTitle,
  navigate,
  nonAuthRedirectUrl,
  redirectUrl,
  voyageId,
  ...props
}) => {
  let errorResponse;

  if (error?.response?.status === 422 || error?.response?.status === 401) {
    const stateToPersist = { ...props?.state?.state, redirectURL: redirectUrl };
    Auth.logout();
    navigate(SIGN_IN_URL, { state: { ...stateToPersist } });
  } else if (
    error?.response?.status === 400 &&
    errorMessage !== DUPLICATE_VESSEL_ERROR &&
    errorMessage !== DUPLICATE_PEOPLE_ERROR
  ) {
    navigate(ERROR_MESSAGE_URL, { state: { id: voyageId } });
  } else {
    const newRedirectURL = error?.response?.status === 500 ? nonAuthRedirectUrl : redirectUrl;
    const stateToPersist = { ...props?.state?.state, redirectURL: newRedirectURL };
    navigate(MESSAGE_URL, {
      state: {
        title: errorTitle || 'Something has gone wrong',
        message: errorMessage || undefined,
        ...stateToPersist,
      },
    });
  }

  return errorResponse;
};

export default handleAuthErrors;

handleAuthErrors.propTypes = {
  error: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  errorTitle: PropTypes.string,
  navigate: PropTypes.func,
  nonAuthRedirectUrl: PropTypes.string,
  redirectUrl: PropTypes.string,
  voyageId: PropTypes.string,
};
