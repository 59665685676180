import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SIGN_IN_URL } from '../constants/AppUrlConstants';
import HelpDetails from '../components/HelpDetails';

const ProtectedRoutes = ({ isPermittedToView }) => {
  const location = useLocation();
  const { state } = location;

  const stateToPersist = { ...state, redirectURL: location };

  return isPermittedToView ? (
    <div>
      <Outlet />
      <HelpDetails />
    </div>
  ) : (
    <Navigate to={SIGN_IN_URL} state={{ ...stateToPersist }} replace />
  );
};

export default ProtectedRoutes;

ProtectedRoutes.propTypes = {
  isPermittedToView: PropTypes.bool.isRequired,
};
