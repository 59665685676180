// External links
export const DATA_PROTECTION_ACT_URL = 'https://www.gov.uk/data-protection';
export const ETA_GUIDANCE_ON_GOVUK_URL =
  'https://www.gov.uk/guidance/apply-for-an-electronic-travel-authorisation-eta';
export const EXAMINING_IDENTITY_DOCS_URL =
  'https://www.gov.uk/government/publications/recognising-fraudulent-identity-documents/guidance-on-examining-identity-documents-accessible';
export const GENERAL_DATA_PROTECTION_URL = 'https://gdpr-info.eu/';
export const IDENTITY_DOCUMENT_GUIDANCE_URL =
  'https://www.gov.uk/government/publications/recognising-fraudulent-identity-documents/guidance-on-examining-identity-documents-accessible';
export const IMMIGRATION_ACT_URL = 'https://www.legislation.gov.uk/ukpga/1971/77/contents';
export const PASSWORD_GUIDENCE_URL =
  'https://www.ncsc.gov.uk/collection/top-tips-for-staying-secure-online/three-random-words#:~:text=Why%20does%20the%20NCSC%20recommend,enough%20for%20you%20to%20remember';
export const PRIVICY_INFO_NOTICE_URL =
  'https://www.gov.uk/government/publications/personal-information-use-in-borders-immigration-and-citizenship';
export const VISA_GUIDANCE_URL =
  'https://www.gov.uk/guidance/immigration-rules/immigration-rules-appendix-visitor-visa-national-list';
export const VISA_IMMIGRATION_URL = 'https://www.gov.uk/browse/visas-immigration';
export const VISA_CHECK_URL = 'https://www.gov.uk/check-uk-visa/y';
export const GUIDANCE_EXAMINING_IDENTITY_DOC =
  'https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1134497/Guidance_on_examining_identity_documents.pdf';
export const UK_VISA_REQUIREMENT_LINK =
  'https://www.gov.uk/government/publications/uk-visa-requirements-list-for-carriers/uk-visa-requirements-for-international-carriers';
export const CHK_IMMIGRATION_STATUS_LINK = 'https://www.gov.uk/check-immigration-status';

// Site
export const GOV_URL = 'https://www.gov.uk/';
export const LANDING_URL = '/';

// Account
export const ACCOUNT_PAGE_URL = '/account';
export const ACTIVATE_ACCOUNT_URL = '/activate-account';
export const DELETE_ACCOUNT_URL = '/account/delete';
export const DELETE_ACCOUNT_SUCCESS_URL = '/account/delete-confirmation';
export const EDIT_ACCOUNT_URL = '/account/edit';
export const INTERSTITIAL_URL = '/information';
export const PASSWORD_FORGOT_URL = '/forgotten-password';
export const PASSWORD_NEW_URL = '/new-password';
export const REGISTER_URL = '/register';
export const REGISTRATION_CONFIRMATION_URL = '/registration-confirmation';
export const RESEND_CODE_URL = '/resend-code';
export const RESEND_EMAIL_USER_NOT_VERIFIED = '/resend-verification-email';
export const SIGN_IN_URL = '/sign-in';
export const VERIFY_URL = '/verify';
// Onboarding
export const ONBOARDING_PEOPLE_URL = '/onboarding/people';
export const ONBOARDING_SKIPPER_URL = '/onboarding/skipper';
// People
export const PEOPLE_PAGE_URL = '/people';
export const PERSON_ADD_URL = '/people/save-person';
export const PERSON_EDIT_URL = '/people/edit-person';
// Pleasure Crafts
export const PLEASURE_CRAFT_ADD_URL = '/pleasure-crafts/save-pleasure-craft';
export const PLEASURE_CRAFT_ADD_PAGE1_URL = '/pleasure-crafts/save-pleasure-craft/page-1';
export const PLEASURE_CRAFT_EDIT_URL = '/pleasure-crafts/edit-pleasure-craft';
export const PLEASURE_CRAFT_PAGE_URL = '/pleasure-crafts';
// Regulatory
export const ACCESSIBILITY_STATEMENT_URL = '/accessibility-statement';
export const HELP_FOOTER_URL = '/help';
/**
 * The help page is linked to directly from emails sent to users, this URL can not be allowed to change, or
 * if it does change, a permanent redirect needs to be left in place to the new location with appropriate documentation.
 */
export const HELP_NAV_URL = '/page/help';
export const PRIVACY_AND_COOKIE_URL = '/privacy-and-cookie-policy';
export const UPT_HELP_URL = '/permission-to-travel-help';
// Voyage
export const CREATE_VOYAGE_URL = '/voyage-plans/start';
export const DASHBOARD_URL = '/voyage-plans';
export const MANAGE_VOYAGE_PLANS = '/manage-voyage-plans';
export const VOYAGE_ARRIVAL_OR_DEPARTURE_UK_URL = '/save-voyage/arrival-or-departure';
export const VOYAGE_ARRIVAL_URL = '/save-voyage/arrival';
export const VOYAGE_CHECK_ANSWERS_URL = '/save-voyage/check-answers';
export const VOYAGE_CUSTOMS_URL = '/voyage-plans/customs';
export const VOYAGE_DEPARTURE_URL = '/save-voyage/departure';
export const VOYAGE_EMPLOYEE_CHECK_URL = '/save-voyage/are-there-employees';
export const VOYAGE_PEOPLE_MANIFEST_URL = '/save-voyage/people-manifest';
export const VOYAGE_EMPLOYEE_SELECT_URL = '/save-voyage/select-employees';
export const VOYAGE_SELECT_PEOPLE_URL = '/save-voyage/select-people';
export const VOYAGE_SELECT_PLEASURE_CRAFT_URL = '/save-voyage/pleasure-craft';
export const VOYAGE_SKIPPER_DETAILS_URL = '/save-voyage/skipper';
export const VOYAGE_SKIPPER_SELECT_URL = '/save-voyage/skipper-select';
export const VOYAGE_SUBMITTED_URL = '/save-voyage/voyage-submitted';
export const VOYAGE_TASK_LIST_URL = '/save-voyage/task-list';
export const VOYAGE_UPT_RESULTS = '/save-voyage/permission-to-travel';

// Miscellaneous
export const MESSAGE_URL = '/message';
export const ERROR_MESSAGE_URL = '/message-voyage';
export const STATUS_COUNT_PART = 'status_count=True';
export const VOYAGE_URL_ID_PART = 'voyageId';

// Top level pages - we use this to know to clear form session data
export const TOP_LEVEL_PAGES = [
  ACCESSIBILITY_STATEMENT_URL,
  ACCOUNT_PAGE_URL,
  DASHBOARD_URL,
  HELP_FOOTER_URL,
  HELP_NAV_URL,
  PEOPLE_PAGE_URL,
  PLEASURE_CRAFT_PAGE_URL,
  PRIVACY_AND_COOKIE_URL,
];

// Pages without back links
export const NO_BACK_LINKS = [
  ...TOP_LEVEL_PAGES,
  ACTIVATE_ACCOUNT_URL,
  DELETE_ACCOUNT_SUCCESS_URL,
  ERROR_MESSAGE_URL,
  INTERSTITIAL_URL,
  LANDING_URL,
  MESSAGE_URL,
  REGISTRATION_CONFIRMATION_URL,
  RESEND_EMAIL_USER_NOT_VERIFIED,
  UPT_HELP_URL,
  SIGN_IN_URL,
  `${VOYAGE_SKIPPER_DETAILS_URL}/page-3`,
  VOYAGE_UPT_RESULTS,
];

export const NO_HELP_DETAILS = [HELP_NAV_URL, HELP_FOOTER_URL];
